body {
  overflow-x:hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Raja */
nav {
	background:#eef;
	padding: 0.3em 0;
  margin-bottom: 2rem;
  position: static;
  display: flex;
  justify-content: space-between; /* Not needed anymore for nav */
  align-items: center;
}
nav * {
  top: 0;
	/*margin: 0 1rem;*/
  display: flex;
}
nav a {
  margin: 0 1rem;
}

.logo {
  margin: 0.5rem;
  width: 3.5rem;
  height: 3.5rem;
}
.logo a {
  margin: 0;
}
.logo img {
  border-radius: 50%;
}
.mainNav a {

}
/* Jeff */
#root {
  min-height: calc(100vh - 72px);
  margin-bottom: 72px;
  width: 100vw;
  background: linear-gradient(45deg, #212529, #191970);
  background-size: 300% 300%;
  animation: gradient-animation 10s ease infinite;
}
.chart-svg {
  max-width: 100%;
}
main {
  padding-top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.textBox {
  text-align: center;
  width: 100%;
  /*padding: 1rem 0;*/
  margin: 0 auto;
}
.textBox .title {
  font-size: 1.8rem;
}
.textBox .text {
  padding-top: 1rem;
  font-size: 1.2rem;
  max-width: 100%;
  height: auto;
}
.splash-container .text-content {
  text-align: left;
}
.splash-container .subtitle {
  font-weight: bold;
}
.splash-container .title .subtitle {
  font-size: 1.8rem;
}
.splash-container .text-content .subtitle {
  font-size: 1.2rem;
}
.subItem {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 0 1rem 1rem 0rem;
  padding: 0.5rem 0;
  width: 100%;
}
.subItem .subItemIcon {
  margin-right: 0.8rem;
}
.subItem .subItemLabel {
  font-weight: bold;
  margin-right: 8px; 
}
.subItem .subItemValue {
  font-style: italic;
}
.subItem .normal {
  font-weight: normal;
}
.login-form input {
  display: block;
  margin: 0.5rem 1rem;
}
footer {
  max-width: 100vw;
  position: fixed;
  bottom: 0;
  background-color: #4B0082 !important;
  color: white;
}
footer .author h6 {
  font-size: 1rem;
}
footer a {
  color: white;
}
.chart-svg {
	width:480px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db; 
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.forecast {
	white-space: pre-wrap;
	line-height: 1.4rem;
  text-align: left;
  padding-bottom: 2rem;
}
.forecast strong {
  display: block;
}
.forecast ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.forecast li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.forecast p {
  margin: 0;
}
.fancyForm {
  max-width: 80vw;
  margin: 0 auto;
}
.fancyForm input {
  width: 100%;
  padding: 10px; 
  margin-bottom: 15px; 
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;   
}
.fancyForm input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
.fancyForm label {
  display: block;    
  margin-bottom: 5px; 
  font-weight: bold;
}
.MuiButton-root {
  background: #4B0082 !important;
}
.MuiButton-root:hover {
  background: #6F2DA8 !important;
}
.MuiContainer-maxWidthSm {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.links {
  padding: 1rem;
}
.pfp {
  width: 80px;
  height: 80px;
  background-position: center;
  border-radius: 50%;
  border: 2px solid black;
}
.pfp-no-border {
  border-radius: 50%;
  border: 2px solid black;
}
.headerLogo {
  border-radius: 50%;
  margin-right: 1rem;
}
.dataBoxes {
  margin: 0 auto;
  border-radius: 2rem;
  /*max-width: 90%;*/
}
.grid {
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 10px;  
}
.grid .subItem {
  display: contents;
}
.grid .subItemIcon {                  
  grid-column: 1;
  align-self: center;
}
.grid .subItemLabel { 
  grid-column: 2;
  align-self: center;
}
.grid .subItemValue {
  grid-column: 2;
  align-self: center;
}
.grid button {
  grid-column: span 2;
}
.headerIcon {
  font-size: 2rem;
  color: #444;
}
.headerIcon:hover {
  font-size: 2rem;
  color: rebeccapurple;
}
header {
  position: fixed !important;
  background-color: #4B0082 !important;
}
main h1 {
  color: #4B0082;
  margin-top: 1rem;
}
main b,
main .subtitle {
  color: #4B0082;
}
/* header h6{
  color: #FFD700;
} */
.mainNav {
  display: flex;
  justify-content: flex-end; /* Align items to the flex-end (right) */
  gap: 15px; 
}
.error-message {
  padding: 1rem 0rem;
  color: red;
}
.fixColumns {
  grid-template-columns: 1fr;
}
.birthdayForm {
  width: 100%;
}